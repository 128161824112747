/*
 * SECTIONS
 */

.c-section {

    width: 100%;
    position: relative;
}

.c-section-secondary {

    background-color: $tertiary;
    border-top: 1px solid $medium;
    border-bottom: 1px solid $medium;
}

.c-section-grey {

    background-color: $medium;
}

.c-section-background-image {

    background-position: center center;
    background-size: cover;


    @include media-query(md) {

        padding-top: 150px;
        padding-bottom: 150px;
    }
}