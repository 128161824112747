.c-nav-mobile-button {
  position: relative;
  z-index: 100;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-width: 44px;
  min-height: 44px;
  overflow: hidden;
  text-indent: -999rem;
  border: 1px solid rgba(#fff, .25);
  
  &:hover {
    cursor: pointer;
  }
}

.c-nav-mobile-button-line {
  display: block;
  width: 22px;
  height: 2px;
  position: absolute;
  left: 50%;
  margin-left: -11px;
  background-color: #fff;
  
  &:nth-child(1) {
    margin-top: -1px;
    top: calc(50% - 6px);
    transition: transform .2s ease-out,
                top .2s .2s ease-out;
  
    .c-nav-mobile > input:checked + .c-nav-mobile-button & {
      top: 50%;
      transform: rotate(45deg);
      transition: top .2s .2s ease-out,
                  transform .2s .4s ease-out;
    }
  }
  
  &:nth-child(2) {
    margin-top: -1px;
    top: 50%;
    transition: all .2s .4s ease-out;
  
    .c-nav-mobile > input:checked + .c-nav-mobile-button & {
      width: 0;
      opacity: 0;
      transition: all .2s ease-out;
    }
  }
  
  &:nth-child(3) {
    margin-bottom: -1px;
    bottom: calc(50% - 6px);
    transition: transform .2s ease-out,
                bottom .2s .2s ease-out;
    
    .c-nav-mobile > input:checked + .c-nav-mobile-button & {
      bottom: 50%;
      transform: rotate(-45deg);
      transition: bottom .2s .2s ease-out,
                  transform .2s .4s ease-out;
    }
  }
}

.c-nav-mobile-list {
  overflow: auto;
  
  // Overlay styled menu
  &--overlay {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99;
    top: -100%;
    left: -100%;
    margin-bottom: 0;
    padding: 2rem;
    list-style: none;
    background-color: rgba($dark, .9);
    opacity: 0;
    transition: opacity .3s ease-out,
    top 0s .3s,
    left 0s .3s;
  
    .c-nav-mobile > input:checked + .c-nav-mobile-button + & {
      top: 0;
      left: 0;
      opacity: 1;
      transition: opacity .3s ease-out;
    }
    
    li {
      display: block;
      margin-bottom: .5rem;
      
      &:last-child {
        margin-bottom: 0;
      }
    }
  
    a {
      width: 100%;
      font-size: rem(24);
      font-weight: bold;
      color: #fff;
      transition: all .2s ease-out;
    
      &:hover {
        color: $tertiary;
      }
    }
  }
  
  // Pushed right menu
  &--pushed-right {
    display: block;
    position: fixed;
    z-index: 99;
    top: 0;
    right: -320px;
    width: 100%;
    max-width: 248px;
    height: 100%;
    padding: 2rem;
    background-color: $dark;
    transition: right .3s ease-out;
  
    .c-nav-mobile > input:checked + .c-nav-mobile-button + & {
      right: 0;
      transition: right .3s ease-out;
    }
    
    li {
      display: block;
      margin-bottom: .5rem;
  
      &:last-child {
        margin-bottom: 0;
      }
    }
  
    a {
      font-size: rem(24);
      font-weight: bold;
      color: #fff;
      transition: all .2s ease-out;
    
      &:hover {
        color: $tertiary;
      }
    }
  }
}