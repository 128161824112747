/*
 * ALERTS
 */

.c-alert {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: .75em 1em;
    background-color: $light;
}

.c-alert-info {
    background-color: $info;
    color: #fff;
}

.c-alert-success {
    background-color: $success;
    color: #fff;
}

.c-alert-warning {
    background-color: $warning;
    color: #fff;
}

.c-alert-error {
    background-color: $error;
    color: #fff;
}

.c-alert-toggle {
    display: block;
    flex: 0 0 auto;
    margin-left: 1em;
    width: 22px;
    height: 22px;
    font-size: rem(22);
    font-weight: bold;
    text-align: center;
    line-height: 22px;
    transition: transform .1s ease;

    &:hover {
        text-decoration: none;
    }

    &:active {
        transform: translateY(1px);
    }
}