.c-nav-menu {

    position: relative;
    z-index: 0;
    transform-style: preserve-3d;
    background-color: white;

    a {

        padding: .2em .4em;
        color: $secondary;
        font-family: 'fairview', Helvetica, sans-serif;
        font-size: 1.5rem;
        font-weight: 500;
        display: inline-block;
        text-decoration: none !important;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            background-color: transparent;
            transition: background-color 175ms linear;
        }

        &:hover {

            &:after {
                background-color: $secondary;
            }
        }
    }

    a.is-active {

        &:after {
            background-color: $secondary;
        }
    }

    @include media-query(md) {

        padding: .2em .4em;
        border: 2px solid $secondary;

        &:after {

            content: "";
            display: block;
            top: 8px;
            left: 8px;
            position: absolute;
            width: 100%;
            height: 100%;
            border: 2px solid $secondary;
            background-color: transparent;
            z-index: -1;
            transform: translateZ(-1px);
        }

        a {
            padding: .2em .4em;
        }
    }
}