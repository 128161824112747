/*
 * MAIN
 */

.c-main {
    width: 100%;
}

.sticky {
    background: #bbb;
    display: flex;
    position: fixed;
}
.toggle-sticky {
    z-index: 20;
}

input, textarea {
    &:focus {
        border-color: $secondary !important;
    }
}

p a {
    &:hover {
        color: $primary !important;
    }
}

p small {
    color: $dark;
}