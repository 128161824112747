/*
 * BASE
 */

html {
    width: 100%;
    height: 100%;
    font-family: $base-font-family;
    @include interpolate(
                    font-size,
                    map-get($breakpoints, xs),
                    map-get($breakpoints, xl),
                    $base-font-size-min,
                    $base-font-size-max
    );
    line-height: $base-line-height;
    color: $base-color;
    @include font-smoothing(on);
}

body {
    width: 100%;
    height: 100%;
    background-color: $dark;
}

p, li {
    a {
        color: $primary;
    }
}

