/*
 * FOOTER
 */

.c-footer {
    width: 100%;
    padding: map-get($spacing, md) 0;
    background-color: $secondary;

    a {
        color: $light;
    }

    address {
        color: $light;
        text-transform: none;
        font-style: normal;
    }

    .c-icon {
        fill: $light;
    }
}