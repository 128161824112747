/*
 * SETTINGS
 */

// GRID
$columns: 12;
$gutter: 16/16*1rem;

$breakpoints: (
    xs: 480/16*1rem,
    sm: 640/16*1rem,
    md: 960/16*1rem,
    lg: 1024/16*1rem,
    xl: 1140/16*1rem
);

// SPACING
$spacing: (
    auto: auto,
    none: 0/16*1rem,
    xs: 8/16*1rem,
    sm: 16/16*1rem,
    md: 24/16*1rem,
    lg: 32/16*1rem,
    xl: 40/16*1rem,
    xxl: 48/16*1rem
);

// COLORS
$info: #2196f3;
$success: #4caf50;
$warning: #ffc107;
$error: #e70638;

$dark: #303030;
$medium: #979797;
$light: #ffffff;

$primary: #AA3821;
$secondary: #467769;
$tertiary: #FDF8F3;

@include fontFace('calibri','../fonts/calibri-webfont');
@include fontFace('fairview','../fonts/fairview_regular-webfont');
@include fontFace('wisdon-script','../fonts/wisdom_script_aj-webfont');

// TYPOGRAPHY
$base-font-family: 'calibri', Helvetica, sans-serif;
$base-font-size-min: 1rem;
$base-font-size-max: 1rem;
$base-font-weight: normal;
$base-line-height: 1.45;
$base-color: $dark;

$h1-font-family: 'wisdon-script', Helvetica, sans-serif;
$h1-font-size: 2rem;
$h1-font-weight: normal;
$h1-line-height: 1.45;
$h1-color: $primary;

$h2-font-family: 'fairview', Helvetica, sans-serif;
$h2-font-size: 2rem;
$h2-font-weight: normal;
$h2-line-height: 1.15;
$h2-color: $secondary;

$h3-font-family: 'fairview', Helvetica, sans-serif;
$h3-font-size: 1.75rem;
$h3-font-weight: bold;
$h3-line-height: 1.45;
$h3-color: $primary;

$h4-font-family: Helvetica, sans-serif;
$h4-font-size: 1.5rem;
$h4-font-weight: bold;
$h4-line-height: 1.45;
$h4-color: $dark;

$h5-font-family: Helvetica, sans-serif;
$h5-font-size: 1.25rem;
$h5-font-weight: bold;
$h5-line-height: 1.45;
$h5-color: $dark;

$h6-font-family: Helvetica, sans-serif;
$h6-font-size: 1.125rem;
$h6-font-weight: bold;
$h6-line-height: 1.45;
$h6-color: $dark;