/*
 * RESET
 */

*,
*:before,
*:after {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
}

body {
    margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block;
}

[hidden] {
    display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
}

p {
    margin-top: 0;
}

strong {
    font-weight: bold;
}

small {
    font-size: 75%;
}

sub,
sup {
    font-size: 75%;
}

a {
    text-decoration: none;
    color: inherit;
}

a:active,
a:hover {
    outline: 0;
}

ul,
ol {
    margin-top: 0;
    padding-left: 0;
    list-style-position: inside;
}

picture {
    //display: block;
    max-width: 100%;
}

img {
    //display: block;
    max-width: 100%;
    border: 0;
}

button,
input,
select,
textarea {
    font: inherit;
    line-height: normal;
    color: inherit;
}

figure {
    margin: 0;
}

button {
    cursor: pointer;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
    padding: 0;
}