
.c-tabs-nav {

    background-color: $light;

    ul {

        align-content: start;

        li {
            display: block;

            a {
                display: block;
                color: $dark
            }

            &:before {
                display: none;
            }

            &:hover {

                background-color: $primary;
                transition: background-color 175ms linear;

                a {
                    color: #ffffff;
                    transition: color 175ms linear;
                }
            }

        }

        li.is-active {

            background-color: $primary;

            a {
                color: #ffffff;

            }
        }
    }
}

.c-tabs-details {

}