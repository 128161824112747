/*
 * CARDS
 */

.c-card-grid {
    > .o-col {
        margin-bottom: $gutter;
    }
}

.c-card {
    display: block;
    background: white;
    box-shadow: 0 8px 30px rgba(black, .1);
}

a.c-card {
    transition: all .2s ease;

    &:hover {
        box-shadow: 0 12px 36px rgba(black, .15);
    }
}