/*
 * BUTTONS
 */

.c-button {

    position: relative;
    font-family: 'fairview', Helvetica, sans-serif;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 100;
    padding: .3em 1em;
    border-radius: 0;
    border: 2px solid $primary;
    color: $primary;
    background-color: white;
    z-index: 0;
    transform-style: preserve-3d;
    text-transform: none !important;

    &:after {

        content: "";
        display: block;
        top: 8px;
        left: 8px;
        position: absolute;
        width: 100%;
        height: 100%;
        border: 2px solid $primary;
        background-color: transparent;
        z-index: -1;
        transform: translateZ(-1px);
    }

    &:hover {

        background-color: white;
        color: $secondary !important;
        text-decoration: none !important;
    }
}

.c-button-secondary {

    border: 2px solid $secondary;
    color: $secondary;

    &:after {

        border: 2px solid $secondary;
    }
}

.c-button-white-bg {

    background-color: white;

    &:hover {

        background-color: white;
    }
}

.c-button-tertiary-bg {

    background-color: $tertiary;

    &:hover {

        background: $tertiary;
    }
}