.c-instagram-image {

    img {

        width: 70px;
        height: 70px;

        @include media-query(sm) {
            width: 140px;
            height: 140px;
        }

        @include media-query(lg) {
            width: 170px;
            height: 170px;
        }

        object-fit: cover;
    }
}
