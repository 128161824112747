.c-menu {

    //column-width: 320px;
    //column-gap: 15px;
    //flex-block-count: 2;
    //flex-block-flow: cross;
}

.c-menu-2-columns {

    @include media-query(lg) {

        column-count: 2;
        column-gap: 48px
    }
}

.c-menu-item {

    //height: auto;
    //display: grid;
    //display: inline-block;
}