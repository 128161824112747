/*
 * HEADER
 */

.c-header {

    position: relative;
    width: 100%;

    a {
        color: $light;
    }

    .c-icon {
        fill: $light;
    }
}

.c-header-info {

    z-index: 1;
    width: 100%;
    position: absolute;
    background-color: rgba(0,0,0,.25);
}

.c-header-hero {

    z-index: 0;
    width: 100%;
    overflow: hidden;
    max-height: 70vh;
    position: relative;

    @include media-query(md) {

        max-height: 60vh;
    }
}

.c-header-hero-logo {

    position: absolute;
    top: 50%;
    left: 50%;
    width: 175px;
    height: 71px;
    margin-left: -87px;
    margin-top: -35px;

    @include media-query(md) {

        width: 350px;
        height: 142px;
        margin-left: -175px;
        margin-top: -71px;

    }
}

.c-header-sticky{

    width: 100%;
    display: flex;
    z-index: 99;
}

.c-header-navigation {

    width: 100%;
    background-color: $primary;
}

.c-header-navigation-main {

    a {

        text-transform: uppercase;
        font-family: 'fairview',  sans-serif;
        font-size: 1.75rem;
        display: inline-block;
        text-decoration: none;

        &:after {

            content: '';
            display: block;
            width: 100%;
            height: 2px;
            background-color: transparent;
            transition: background-color 175ms linear;
        }

        &:hover {

            text-decoration: none;

            &:after {
                background-color: $light;
            }
        }
    }

    a.active {

        &:after {
            background-color: $light;
        }
    }
}

.c-header-navigation-mobile {

    h2 {

        text-transform: uppercase;
        font-family: 'fairview',  sans-serif;
        font-size: 1.75rem;
        color: $light;
    }
}