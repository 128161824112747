/*
 * CARDS
 */

.c-card {

    position: relative;
    display: block;
    background-color: white;
    box-shadow: none !important;
    z-index: 0;
    transform-style: preserve-3d;

    &:after {

        content: "";
        display: block;
        top: 14px;
        left: 14px;
        position: absolute;
        width: 100%;
        height: 100%;
        border: 4px solid $light;
        background-color: transparent;
        z-index: -1;
        transform: translateZ(-1px);
    }
}

.c-card-primary, .c-card-secondary {

    h1, h2, h3, h4, h5, h6, p, a {

        color: white;
    }

    a {

        font-weight: bold;
    }
}

.c-card-primary {

    background-color: $primary;

    &:after {

        border: 4px solid $primary;
    }
}

.c-card-secondary {

    background-color: $secondary;

    &:after {

        border: 4px solid $secondary;
    }
}