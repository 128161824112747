/*
 * BUTTONS
 */

.c-button {
    display: inline-block;
    padding: .625em 1.125em;
    background-color: $light;
    border-radius: 3px;
    transition: all .1s ease;

    &:hover {
        background-color: darken($light, 5%);
    }
}

.c-button-info {
    background-color: $info;
    color: #fff;

    &:hover {
        background-color: darken($info, 5%);
    }
}

.c-button-success {
    background-color: $success;
    color: #fff;

    &:hover {
        background-color: darken($success, 5%);
    }
}

.c-button-warning {
    background-color: $warning;
    color: #fff;

    &:hover {
        background-color: darken($warning, 5%);
    }
}

.c-button-error {
    background-color: $error;
    color: #fff;

    &:hover {
        background-color: darken($error, 5%);
    }
}

.c-button-primary {
    background-color: $primary;
    color: #fff;

    &:hover {
        background-color: darken($primary, 5%);
    }
}

.c-button-secondary {
    background-color: $secondary;
    color: #fff;

    &:hover {
        background-color: darken($secondary, 5%);
    }
}

.c-button-tertiary {
    background-color: $tertiary;
    color: #fff;

    &:hover {
        background-color: darken($tertiary, 5%);
    }
}